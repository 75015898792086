const optimizelySdk = require('@optimizely/optimizely-sdk');
const buildEnvs = require('../../../_envs.json');

const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'localhost';
const optimizelySdkKey = buildEnvs[ activeEnv ].OPTIMIZELY_FEATURE_SDK;
const optimizely = optimizelySdk?.createInstance({
    sdkKey: optimizelySdkKey,
});

const userContext = function(op) {
	if (op && window?.ql_dl?.getUser) {
		const user = window?.ql_dl?.getUser();
		const userContext = op.createUserContext(user.visitorId, user);
		window?.ql_dl?.setUserContext(userContext);
		window?.ql_dl?.initializeOptimizelyFeatX(op);
		return userContext;
	}
	return op ? op.createUserContext() : null;
}
const trackingLoaded = () => {
	return new Promise((resolve) => { 
		if (!( 'ql_dl' in window)) {
			resolve();
		}
		window.ql_dl.addEventListener(() => {
			resolve();
		});
	});
};
const timer = (time) => new Promise((resolve) => setTimeout(() => resolve(), time));
let instance = {
    flags: {},
    init: async () => {
        if (!instance.userContext)
        {
	    await Promise.race([trackingLoaded(), timer(1000)]);
            const { success, reason } = await optimizely?.onReady();
            instance.userContext = userContext(optimizely);
            instance.status = success;
            if (!success)
            {
                console.error(`Optimizely SDK error: ${reason}`);
            }
        }
    },
    userContext: userContext(),
    status: false,
};

export default Object.freeze({
    get: (flagName) =>
    {
        if (!instance.userContext)
        {
            return null;
        }
	if (flagName in instance.flags) {
		return instance.flags[flagName];
	}
        instance.flags[flagName] = instance.userContext.decide(flagName);
	const { ruleKey, flagKey, variationKey, enabled } = instance.flags[flagName];
	window.dataLayer.push({ 'event': 'OptimizelyFeatureX', ruleKey, flagKey, variationKey });
        
        return instance.flags[flagName];
    },
    getStatus: () => instance.status,
    init: () => instance.init(),
});
